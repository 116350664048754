<template>
<main>
  <RouterView></RouterView>
</main>
<!-- <img src="/images/background/xmastree.jpg" alt=""> -->
</template>

<script setup>
import { onMounted } from 'vue'
// import { useStore } from 'vuex'

// const store = useStore()

onMounted(() => {
  // updateCurrentDateTime();
})

// function updateCurrentDateTime() {
//   var now = new Date()
//   store.commit('setCurrentDate', now)
//   requestAnimationFrame(updateCurrentDateTime)
// }

</script>

<style lang="scss">
body{
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100vw;
}


#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
  background-color: #000;
  height: 100%;
  width: 100%;
  main{
    height: 100%;
    width: 100%;
    position: relative;
    z-index: 1;
  }
  img{
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
  }
}

</style>
