<script setup>
import { onMounted } from 'vue'

onMounted(() => {

  var clock = document.querySelector('#utility-clock')
  utilityClock(clock)
  autoResize(clock, 295 + 32)
})

function utilityClock(container) {
    var dynamic = container.querySelector('.dynamic')
    var hourElement = container.querySelector('.hour')
    var minuteElement = container.querySelector('.minute')
    var secondElement = container.querySelector('.second')
    var minute = function(n) {
        return n % 5 == 0 ? minuteText(n) : minuteLine(n)
    }
    var minuteText = function(n) {
        var element = document.createElement('div')
        element.className = 'minute-text'
        element.innerHTML = (n < 10 ? '0' : '') + n
        position(element, n / 60, 135)
        dynamic.appendChild(element)
    }
    var minuteLine = function(n) {
        var anchor = document.createElement('div')
        anchor.className = 'anchor'
        var element = document.createElement('div')
        element.className = 'element minute-line'
        rotate(anchor, n)
        anchor.appendChild(element)
        dynamic.appendChild(anchor)
    }
    var hour = function(n) {
        var element = document.createElement('div')
        element.className = 'hour-text hour-' + n
        element.innerHTML = n
        position(element, n / 12, 110)
        dynamic.appendChild(element)
    }
    var position = function(element, phase, r) {
        var theta = phase * 2 * Math.PI
        element.style.top = (-r * Math.cos(theta)).toFixed(1) + 'px'
        element.style.left = (r * Math.sin(theta)).toFixed(1) + 'px'
    }
    var rotate = function(element, second) {
        element.style.transform = element.style.webkitTransform = 'rotate(' + (second * 6) + 'deg)'
    }
    var animate = function() {
        var now = new Date()
        var time = now.getHours() * 3600 +
                    now.getMinutes() * 60 +
                    now.getSeconds() * 1 +
                    now.getMilliseconds() / 1000
        rotate(secondElement, time)
        rotate(minuteElement, time / 60)
        rotate(hourElement, time / 60 / 12)
        requestAnimationFrame(animate)
    }
    for (let i = 1; i <= 60; i ++) minute(i)
    for (let i = 1; i <= 12; i ++) hour(i)
    animate()
}

function autoResize(element, nativeSize) {
  var update = function() {
    var parent = element.offsetParent
    var scale = Math.min(parent.offsetWidth, parent.offsetHeight) / nativeSize
    element.style.transform = element.style.webkitTransform = 'scale(' + scale.toFixed(3) + ')'
  }
  update()
  window.addEventListener('resize', update)
}

</script>

<template>
<div class="fill">
  <div class="reference"></div>
  <div class="clock" id="utility-clock">
    <!-- <div class="centre"> -->
      <div class="dynamic"></div>
      <div class="expand round circle-1"></div>
      <div class="anchor hour">
        <div class="element thin-hand"></div>
        <div class="element fat-hand"></div>
      </div>
      <div class="anchor minute">
        <div class="element thin-hand "></div>
        <div class="element fat-hand minute-hand"></div>
      </div>
      <div class="anchor second">
        <div class="element second-hand"></div>
      </div>
      <div class="expand round circle-2"></div>
      <div class="expand round circle-3"></div>
    <!-- </div> -->
  </div>
</div>
</template>

<style lang="scss" scoped>

$text-color: #ffffffff;

$thin-hand-width: 4px;
$fat-hand-width: 7px;

$minute-hand-color: #fff;
$hour-hand-color: #e51919;

.fill {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 50%;
    left: 0;
}

.clock {
    position: absolute;
    opacity: 1;
    &:before{
      content: "";
      position: absolute;
    //   background: radial-gradient(circle, rgba(255,255,255,0) 0%, rgba(255,255,255,.1) 100%);
    //   backdrop-filter: blur(3px);
    //   box-shadow: 0 0 60px #ffffff36;
      width: 300px;
      height: 300px;
      transform: translate(-50%, -50%);
      border-radius: 50%;
    //   border: 1px solid #ffffff22;
      // top: 0;
      // left: 0;
      // right: 0;
      // bottom: 0;
    }
}

.fill .clock {
    left: 50%;
    top: 50%;
}

// .centre {
//     position: absolute;
//     top: 50%;
//     left: 50%;
//     width: 0;
//     height: 0;
// }

::v-deep .expand {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-50%, -50%);
}


::v-deep  .anchor {
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      height: 0;
  }

  ::v-deep .element {
    position: absolute;
    top: 0;
    left: 0;
}

::v-deep .round {
    border-radius: 296px;
}

.circle-1 {
    background: white;
    width: 12px;
    height: 12px;
}

.circle-2 {
    background: #FA9F22;
    width: 8px;
    height: 8px;
}

.circle-3 {
    background: black;
    width: 4px;
    height: 4px;
}

.second {
    transform: rotate(180deg);
}

.minute {
    transform: rotate(54deg);
    div{
        background-color: $minute-hand-color;
    }
}

.second-hand {
    width: 1px;
    height: 160px;
    background: #FA9F22;
    transform: translate(-50%,-100%) translateY(24px);
}

.hour {
    transform: rotate(304.5deg);
    div{
        background-color: $hour-hand-color;
    }
}

.thin-hand {
    width: $thin-hand-width;
    height: 50px;
    // background: $thin-hand-color;
    transform: translate(-50%,-100%);
}

.fat-hand {
    width: $fat-hand-width;
    height: 60px;
    border-radius: 10px;
    // background: $fat-hand-color;
    transform: translate(-50%,-100%) translateY(-18px);
}

::v-deep .minute-hand {
    height: 112px;
}

::v-deep .hour-text {
    position: absolute;
    font: 25px Hei, Helvetica, Arial, sans-serif;
    color: $text-color;
    transform: translate(-50%,-50%);
}

.hour-10 {
    padding-left: 0.4ex;
}
.hour-11 {
    padding-left: 0.25ex;
}

::v-deep .minute-text {
    position: absolute;
    font: 8px Avenir Next, Helvetica, Arial, sans-serif;
    color: $text-color;
    transform: translate(-50%,-50%);
}

::v-deep .minute-line {
    background: white;
    width: 1px;
    height: 9px;
    transform: translate(-50%,-100%) translateY(-131px);
    opacity: 0.34;
}
</style>