<template>
    <div id="calendar">
      <div v-for="(day, calindex) in calendar" :key="calindex" :class="['day','day-' + (calindex + 1), {holiday: day.isHoliday}]">
        <div class="date">
          {{ dateFormat(day.date, "d") }}
          <span class="week">{{ dateFormat(day.date, "eee") }}</span>
        </div>
        <ul>
            <template v-for="(event, index) in googleCalendars" :key="index">
              <template v-if="event.start.dateTime">
                <li class="event" v-if="checkSameDate(day.date,event.start.dateTime)">
                  <span class="bullet" :style="{color: event.color}">●</span>
                  <span class="time">{{ dateFormat(new Date(event.start.dateTime), 'kk:mm') }}</span>{{ event.summary }}
                </li>
              </template>
              <template v-else-if="event.start.date">
                <li :class="['event','all_day',{holiday_event: holidayCheck(event.holidaySW,calindex)}]" v-if="checkSameDate(day.date,event.start.date)">
                  <span class="bullet" :style="{color: event.color}">●</span>
                  <span class="time">終日</span>{{ event.summary }}
                </li>
              </template>
            </template>
        </ul>
      </div>
    </div>
  </template>

  <script>
// import { ipcRenderer } from 'electron'
import { add, format, isSameDay,isSaturday,isSunday } from 'date-fns'
import ja from 'date-fns/locale/ja'

  export default {
    data() {
      return {
        tokenClient: "",
        gapi: {},
        gapiInited: false,
        gisInited: false,
        calendarDays: 7,
        calendar: [],
        googleCalendars: [],
        authToken: '',
        calendarVersion: 'v3',
        clientId: '403052202766-rog6coefa7j60krf9k8ljvf8ll2euhfa.apps.googleusercontent.com',
        calendarLists: [
          { // 由有
            // calendarId: 'arayag@gmail.com',
            calendarId: 'primary',
            isHolidayCalendar: false,
            color: '#4b9eed'
          },
          { // 家族イベント
            calendarId: 'm5cefislrnq51a9phmpm6cvk78@group.calendar.google.com',
            isHolidayCalendar: false,
            color: '#fa9f22'
          },
          { //朋子
            calendarId: 'rt77nmohf4hgvp3h13n70v04nc@group.calendar.google.com',
            isHolidayCalendar: false,
            color: '#d873d5'
          },
          { //祝日
            calendarId: 'ja.japanese#holiday@group.v.calendar.google.com',
            isHolidayCalendar: true,
            color: '#ee0000'
          }
        ]
      }
    },
    mounted() {
      this.createCalender()
      // console.log(window.gapi)
      // ipcRenderer.sendSync("auth", { message: "hello" });
      // window.ipcRenderer.send('auth', 'hellohellohellohellohellohellohellohellohellohellohellohello')
    },
    created() {
      // this.gapi = window.gapi
      // const id = setInterval(() => {
      //           if(window.gapi){
      //               this.gapi = window.gapi;
      //               // console.log(this.gapi)
      //               this.handleClientLoad();
      //               // this.loadCalendars();
      //               clearInterval(id);
      //           }
      //       }, 1000);
      // this.loadCalendars();

      let gapi = document.createElement('script')
      gapi.setAttribute('src', 'https://apis.google.com/js/api.js')
      gapi.onload = () => {
        this.gapi = window.gapi;
        this.gapi.load('client', this.initializeGapiClient);
      }
      document.head.appendChild(gapi)
      
      let gsi = document.createElement('script');
      gsi.setAttribute('src', 'https://accounts.google.com/gsi/client')
      gsi.onload = () => {
        this.tokenClient = window.google.accounts.oauth2.initTokenClient({
          client_id: '403052202766-rog6coefa7j60krf9k8ljvf8ll2euhfa.apps.googleusercontent.com',
          scope: 'https://www.googleapis.com/auth/calendar.readonly',
          callback: '', // defined later
        });
        this.gisInited = true;
        this.maybeEnableButtons();
      }
      document.head.appendChild(gsi)
    
      // this.google = window.google
      // const id = setInterval(() => {
      //           if(window.google){
      //               this.google = window.google;
      //               // console.log(this.gapi)
      //               this.test()
      //               // this.loadCalendars();
      //               clearInterval(id);
      //           }
      //       }, 1000);

    },
    methods: {
      async initializeGapiClient() {
        await this.gapi.client.init({
          apiKey: 'AIzaSyDKrTkti_dkTC-nm20JTdUj1eOxVbOPEL4',
          discoveryDocs: ['https://www.googleapis.com/discovery/v1/apis/calendar/' + this.calendarVersion + '/rest'],
        });
        this.gapiInited = true;
        this.maybeEnableButtons();
      },
      maybeEnableButtons() {
        if (this.gapiInited && this.gisInited) {
          this.handleAuthClick();
        }
      },
      handleAuthClick() {
        this.tokenClient.callback = async (resp) => {
          if (resp.error !== undefined) {
            throw (resp);
          }
          // document.getElementById('signout_button').style.visibility = 'visible';
          // document.getElementById('authorize_button').innerText = 'Refresh';
          await this.loadCalendars();
        };

        if (this.gapi.client.getToken() === null) {
          // Prompt the user to select a Google Account and ask for consent to share their data
          // when establishing a new session.
          this.tokenClient.requestAccessToken({prompt: 'consent'});
        } else {
          // Skip display of account chooser and consent dialog for an existing session.
          this.tokenClient.requestAccessToken({prompt: ''});
        }
      },
      createCalender() {
        const today = new Date();
        for (var i = 0; i < this.calendarDays; i++){
          let date = add(today, { days: i });
          let dayData = {
              date: date,
              isHoliday: (isSaturday(date) || isSunday(date)) ? true : false
          }
          this.calendar.push(dayData)
        }
      },
      test() {
        this.google.accounts.id.initialize({
          client_id          : '403052202766-rog6coefa7j60krf9k8ljvf8ll2euhfa.apps.googleusercontent.com',
          prompt_parent_id   : 'google_login',
          style              : 'position:static;',
          auto_prompt        : true,
          auto_select        : true,
          cancel_on_tap_outside : false,
          callback           : this.logined_callback,
        })
        this.google.accounts.id.prompt();
      },
      logined_callback() {

        this.loadCalendars()
      },
      // handleClientLoad () {
      //     this.gapi.load('client:auth2', this.loadCalendars);
      // },
      async loadCalendars() {
        for (let i = 0; i < this.calendarLists.length; i++) {
            var calendarId = this.calendarLists[i].calendarId;
            let response;
            try {
              const request = {
                'calendarId': calendarId,
                'timeMin': new Date().toISOString(),
                'timeMax': add(new Date(), { days: this.calendarDays }).toISOString(),
                'showDeleted': false,
                'singleEvents': true,
                'maxResults': 20,
                'orderBy': 'startTime'
              };
              response = await this.gapi.client.calendar.events.list(request);
            } catch (err) {
              console.log(err.message)
              return;
            }
            console.log(response)
            var events = response.result.items;
            if (events.length > 0) {
              // var calendar = {
              //   summary: resp.summary,
              //   events: events
              // };
              // this.googleCalendars.push(calendar);
              for (const event of events) {
                event.color = this.calendarLists[i].color;
                if (this.calendarLists[i].isHolidayCalendar) {
                  event.holidaySW = true
                }
                this.googleCalendars.push(event);
              }

              this.googleCalendars.sort((date1, date2) => -(new Date(date1.start.dateTime).getTime()) - (new Date(date2.start.dateTime).getTime()));
            } else {
              console.log('No events found for calendar ' + calendarId);
            }
        }
      },
      loadCalendars_old() {
        // Google APIクライアントライブラリを初期化する
        this.gapi.client.init({
          'apiKey': 'AIzaSyDKrTkti_dkTC-nm20JTdUj1eOxVbOPEL4',
          'clientId': this.clientId,
          // 'clientSeacret': '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',
          'scope': 'https://www.googleapis.com/auth/calendar.readonly',
          'discoveryDocs': ['https://www.googleapis.com/discovery/v1/apis/calendar/' + this.calendarVersion + '/rest'],
        }).then(() => {
          // 認証情報を取得する
          this.gapi.auth2.getAuthInstance().signIn().then(() => {
            // 認証トークンを取得する
            this.authToken = this.gapi.auth2.getAuthInstance().currentUser.get().getAuthResponse().access_token;

            // カレンダーの一覧を取得する
            for (let i = 0; i < this.calendarLists.length; i++) {
              var calendarId = this.calendarLists[i].calendarId;
              var request = this.gapi.client.calendar.events.list({
                'calendarId': calendarId,
                'timeMin': new Date().toISOString(),
                'timeMax': add(new Date(), { days: this.calendarDays }).toISOString(),
                'showDeleted': false,
                'singleEvents': true,
                'maxResults': 20,
                'orderBy': 'startTime'
              });

              // 各カレンダーのイベントを取得する
              request.execute((resp) => {
                var events = resp.items;
                if (events.length > 0) {
                  // var calendar = {
                  //   summary: resp.summary,
                  //   events: events
                  // };
                  // this.googleCalendars.push(calendar);
                  for (const event of events) {
                    event.color = this.calendarLists[i].color;
                    if (this.calendarLists[i].isHolidayCalendar) {
                      event.holidaySW = true
                    }
                    this.googleCalendars.push(event);
                  }

                  this.googleCalendars.sort((date1, date2) => -(new Date(date1.start.dateTime).getTime()) - (new Date(date2.start.dateTime).getTime()));
                } else {
                  console.log('No events found for calendar ' + calendarId);
                }
              });
            }

          });
        }, (error) => {
          console.error(error);
        });
      },
      checkSameDate(dateLeft, dateRight) {
        // console.log(dateLeft, dateRight)
        dateRight = new Date(dateRight)
        return isSameDay(dateLeft, dateRight)
      },
      holidayCheck(holidaySW, calindex) {
        if (holidaySW) this.calendar[calindex].isHoliday = true;
      }
    },
    computed: {
      currentDate () {
        return this.$store.state.currentDate
      },
      dateFormat() {
        return (day, fmt) => {
          return format(day, fmt, {locale: ja})
        }
      }
    }
  }

  </script>

<style lang="scss">
  #calendar{
    position: absolute;
    width: 90%;
    margin: auto;
    bottom: 0;
    right: 0;
    left: 0;

    display: grid;
grid-template-columns: 30% 1fr;
grid-template-rows: repeat(6, 1fr);
grid-column-gap: 20px;
grid-row-gap: 20px;

    .date{
      font-size: 45px;
      width: 50px;
      display: flex;
      flex-direction: column;
      align-items: center;
      line-height: 1em;

      .week{
        line-height: 1em;
        font-size: 0.4em;
      }
    }
    .day{

      &-1 { grid-area: 1 / 1 / 7 / 2; }
      &-2 { grid-area: 1 / 2 / 2 / 3; }
      &-3 { grid-area: 2 / 2 / 3 / 3; }
      &-4 { grid-area: 3 / 2 / 4 / 3; }
      &-5 { grid-area: 4 / 2 / 5 / 3; }
      &-6 { grid-area: 5 / 2 / 6 / 3; }
      &-7 { grid-area: 6 / 2 / 7 / 3; }

      display: flex;
      // margin-bottom: 2vh;
      gap: 2vw;

      &-1{
        flex-direction: column;
        .date{
          flex-direction: row;
          font-size: 70px;
        }
      }

      &.holiday{
        .date{
          color: red;

        }
      }
      ul{
        margin: 0;
        padding: 0;
      }
      .event{
        list-style: none;
        &.all_day{
          .time{
            border: 1px solid #ffffff55;
            border-radius: 10px;
            font-size: 0.9em;
          }
        }
        .time{
          display: inline-block;
          width: 50px;
          line-height: 1em;
          text-align: center;
          padding: 3px 5px;
          margin: 0 10px;
        }
      }
    }
  }
</style>