<template>
  <div class="settings">
    <div class="modal">
      setting
    </div>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.settings{
  background-color: #00000044;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* .modal{
    position: absolute;
    max-width: 80%;
    background-color: #fff;
  } */
}
</style>
